import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import styles from "./style.scss";

export default class TextArea extends React.Component {
  static propTypes = {
    dataTut: PropTypes.string,
    defaultValue: PropTypes.string,
    maxLength: PropTypes.number,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    isEditTextArea: PropTypes.bool,
  };

  static defaultProps = {
    defaultValue: "",
    maxLength: 140,
  };

  constructor(props) {
    super(props);

    this.state = {
      charactersLeft:
        this.props.maxLength - (this.props.defaultValue || "").length,
    };
  }

  handleChange = (event) => {
    const currentLength = event.target.value.length;

    this.setState({
      charactersLeft: this.props.maxLength - currentLength,
    });
  };

  render() {
    const { dataTut, isEditTextArea } = this.props;

    if (isEditTextArea) {
      return (
        <div className={classNames(styles.wrapper)} data-tut={dataTut}>
          <textarea
            maxLength={this.props.maxLength}
            onChange={this.handleChange}
            defaultValue={this.props.defaultValue}
            placeholder={this.props.placeholder}
            name={this.props.name}
            className={styles.textArea}
          />
          <span className={classNames(styles.counterWrapper)}>
            {this.state.charactersLeft} Characters Left
          </span>
        </div>
      );
    }

    return (
      <div className={classNames(styles.wrapper)} data-tut={dataTut}>
        <textarea
          maxLength={this.props.maxLength}
          onChange={this.handleChange}
          defaultValue={this.props.defaultValue}
          placeholder={this.props.placeholder}
          name={this.props.name}
          className={styles.textArea}
        />
        <span className={classNames(styles.counterWrapper)}>
          {this.state.charactersLeft} Characters Left
        </span>
      </div>
    );
  }
}
