// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.components-TextArea-style__counter__Y1JtI{display:block;font-weight:normal;padding-top:.5em}.components-TextArea-style__wrapper__eSe2L{width:100%;margin-bottom:12px}.components-TextArea-style__wrapper__eSe2L .components-TextArea-style__textArea__AU2sT{width:100%;border-radius:16px;background:#f9f9f9;resize:none;font-family:"Roboto",sans-serif;font-size:12px;font-style:normal;height:110px;padding:15px 21px;margin:0}.components-TextArea-style__wrapper__eSe2L .components-TextArea-style__counterWrapper__TfN2m{margin-top:4px;width:100%;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:end;-ms-flex-pack:end;justify-content:flex-end;color:#939393;font-family:"Poppins",sans-serif;font-size:10px;font-style:normal;font-weight:400;line-height:normal}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"counter": `components-TextArea-style__counter__Y1JtI`,
	"wrapper": `components-TextArea-style__wrapper__eSe2L`,
	"textArea": `components-TextArea-style__textArea__AU2sT`,
	"counterWrapper": `components-TextArea-style__counterWrapper__TfN2m`
};
export default ___CSS_LOADER_EXPORT___;
